<template>
  <section
    id="detention"
    class="overflow-hidden"
  >
    <v-row no-gutters>
      <v-col
        class="hidden-sm-and-down"
        md="6"
      >
        <v-img
          :src="require('@/assets/天橋上的魔術師_3.jpg')"
          height="100%"
        />
      </v-col>
      <v-col
        class="primary text-center pa-5 white--text"
        cols="12"
        md="6"
      >
        <base-heading>
          The Magician on the Skywalk<br>
          天橋上的魔術師
        </base-heading>

        <base-text class="mb-5 text-left">
          <p>このドラマは、1985年の夏、中華商場の靴屋の息子で小学校4年生の少年が毎日のように歩道橋でマジックを見せる男との出会いから始まる物語。</p>
        </base-text>

        <v-card color="secondary">
          <v-container class="pa-2">
            <v-row>
              <v-col cols="6">
                <v-img
                  :src="require(`@/assets/天橋上的魔術師_1.jpg`)"
                  max-height="300"
                />
              </v-col>
              <v-col cols="6">
                <v-img
                  :src="require(`@/assets/天橋上的魔術師_2.jpg`)"
                  max-height="300"
                />
              </v-col>
              <v-col cols="6">
                <v-img
                  :src="require(`@/assets/天橋上的魔術師_3.jpg`)"
                  max-height="300"
                />
              </v-col>
              <v-col cols="6">
                <v-img
                  :src="require(`@/assets/天橋上的魔術師_4.jpg`)"
                  max-height="300"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </section>
</template>

<script>
  export default {
    name: 'TheMagicianOnTheSkyWalk',
  }
</script>
